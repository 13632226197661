<script>
import { useRoute, useRouter } from 'vue-router/composables'
import { useCompetitions } from '/~/extensions/competitions/composables'
import BaseButton from '/~/components/base/button/base-button.vue'

export default {
  name: 'competition-confirmation',
  components: {
    BaseButton,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { competitionSubmittedDetails } = useCompetitions()

    if (!competitionSubmittedDetails.value) {
      router.push({
        name: 'competition-details',
        params: {
          id: route.params.id,
        },
      })
    }

    function gotIt() {
      router.push({ name: 'competitions' })
    }

    return {
      competitionSubmittedDetails,
      gotIt,
    }
  },
}
</script>

<template>
  <div v-if="competitionSubmittedDetails" class="m-auto max-w-screen-xs">
    <div class="mt-12 flex flex-col items-center">
      <img src="/icons/v3/success.svg" class="w-96" />
      <h2 class="mb-[30px] mt-5 w-full px-5 text-center text-eonx-neutral-800">
        Thank you for your entry!
      </h2>
    </div>
    <div class="m-auto mx-5 space-y-2.5 rounded-3xl bg-white p-[30px] md:px-10">
      <template
        v-for="(field, index) in competitionSubmittedDetails.formFields"
      >
        <div v-if="field.name !== 'terms'" :key="index">
          <span class="flex items-center text-eonx-neutral-600">
            <span>
              {{ field.label }}
            </span>
          </span>
          <b class="text-eonx-neutral-800">
            {{ competitionSubmittedDetails.form[field.name] }}
          </b>
        </div>
      </template>
    </div>
    <div class="flex justify-center px-5">
      <base-button full-width="mobile" class="my-[30px]" @click="gotIt">
        <span class="w-48">Got it</span>
      </base-button>
    </div>
  </div>
</template>
